.proposal-widget {
  margin: 15vw 0 10vw 0;
  padding: 1rem;

  .proposal-info {
    display: block;
    margin: 1rem 0 1rem 0;
    white-space: pre-wrap;
  }

  .proposal-link {
    text-decoration: underline;
  }
  .proposal-link:hover {
    text-decoration: none;
  }

  .widget {
    display: grid;
    grid-template-columns: 1fr 4rem 1rem 3rem;
    margin-top: 1rem;

    .blocks-container {
      height: 6rem;
      width: 100%;
      position: relative;
    }

    .voice-credits {
      grid-column: 2;
      margin: auto 1vw auto 1vw;
      text-align: center;
    }

    .equals {
      grid-column: 3;
      margin: auto 8vw auto auto;
    }

    .counter-container {
      grid-column: 4;
      text-align: center;

      .arrow {
        border: none;
        outline: none;
        background: inherit;
        cursor: pointer;
      }
      .arrow:active {
        .svg {
          .path {
            stroke-width: 2.5;
          }
        }
      }

      .vote-counter {
        // margin: 2vw;
      }
    }
  }
}

#ratification-proposal {
  border: 1px solid black;
  border-radius: 8px;

  .proposal-info {
    // font-weight: bolder;
  }
}

@media only screen and (min-width: 768px) {
  .proposal-widget {
    margin: 3vw 0 3vw 0;

    .proposal-info {
    }

    .widget {
      grid-template-columns: 1fr 5rem 2rem 5rem;

      .blocks-container {
        height: 6rem;
      }

      .voice-credits {
        margin: auto;
      }

      .equals {
        margin: auto;
      }

      .counter-container {

        .vote-counter {
          // margin: 2vw;
        }
      }
    }
  }
}
