.forgot-password {
  padding: 4.5vmin;
  max-width: 400px;
  margin: auto;

  .email-input {
    display: block;
    margin: 8vmin auto 0 auto;
    text-align: center;
    width: 80%;
    border: none;
    outline: none;
    border-bottom: 1px solid black;
    font-size: 1rem;

  }

  .submit-button {
    display: block;
    text-align: center;
    margin: 10vmin auto 5vmin auto;
    padding: 1vmin 4vmin;
    background: var(--blackColor);
    color: white;
    border: 1px solid black;
    border-radius: 15px;
    outline: none;
    cursor: pointer;
    font-size: 1rem;
  }
  .submit-button:hover {
    background: var(--whiteColor);
    color: black;
  }

}
