.landing-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
}

#top-panel {
  background: black;
  color: #f0fc62;
  height: 100vh;
  display: grid;
  grid-template-rows: auto auto 1fr;
}

.lp-header {
  background: black;
  padding: .8rem 1.5rem .8rem 1.5rem;
}

.logo-container {
  .rxc-logo {
    display: none;
  }
}
.App-logo {
  width: 250px;
}
.home-header_button {
  margin: 0 !important;
  padding: 0 !important;
  color: #f0fc62;
  text-transform: uppercase;
  font-size: 1rem;
}

.landing-page_nav {
  display: flex;
  justify-content: space-between;
  padding: auto 0 auto 0;
  margin: auto 0;
}

.top-panel_row-2 {
  background: #f0fc62;
}
.top-panel_row-2 > img {
  margin: 4rem 0 1rem 0;
}

.top-panel_row-3 {
  color: #f0fc62;
  font-size: 1.8rem;
  padding-top: 1.5rem;
}
.top-panel_row-3 > a > button {
  background: #f0fc62;
  color: black;
  display: inline-block;
  margin: 2rem 1.5rem;
}

.subtitle {
  width: 70%;
  max-width: 450px;
  margin: 0 auto 0 auto;
}

.scroll-arrow {
  margin-bottom: 1rem;
}

#info {
  width: 100%;
}

.panel {
  min-height: 100vh;
}

.subpanel_image {
  background: #f0fc62;
  width: 100%;
  min-height: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subpanel_image > img {
  display: block;
  margin: auto auto;
  width: 85%;
}

.subpanel_text {
  display: inline-block;
  padding: 2rem;
  line-height: 1.5rem;
  color: white;
  background: black;
  text-align: left;
}

.subpanel_text > h2 {
  color: #f0fc62;
  font-size: 2rem;
}

.subpanel_subtitle {
  color: #faffc3;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

#info > section:nth-child(odd) > div.subpanel_image {
  grid-column: 1;
  grid-row: 1;
}

#info > section:nth-child(odd) > div.subpanel_text {
  grid-column: 2;
  grid-row: 1;
}

#first-panel {
  background: #f0fc62;
  padding: 2rem 1rem;

  #first-panel_header {
  }
  #stages {
  }

  .stage {
    display: grid;
    grid-template-rows: 1fr auto;
    min-height: 100vw;
  }

  .stage > img {
    max-width: 100%;
    margin: auto;
  }
}

#first-panel_header > h2 {
  font-size: 2.5rem;
  margin: 0 0 .5rem 0 !important;
}

#first-panel > div > p {
  font-size: 1rem;
  margin: .5rem 0;
}

#last-panel {
  background: black;
  color: white;
  text-align: center;
  padding: 2rem;
  line-height: 1.5rem;
}

#last-panel > div {
  max-width: 600px;
  margin: auto;
}

#last-panel > div > h2 {
  font-size: 2rem;
  line-height: 2rem;
}

#last-panel_text {
  max-width: 400px;
  margin: auto;
}

#last-panel_text > p {
  margin: 3rem 0;
}

.white-link {
  color: white !important;
}

@media only screen and (min-width: 768px) {

  .lp-header {
    display: grid;
    grid-template-columns:  1fr 40%;
  }

  .logo-container {
    margin-right: auto;

    .rxc-logo {
      display: block;
      width: 250px;
    }
  }

  .App-logo {
    width: auto;
  }

  #first-panel {
    display: grid;
    grid-template-rows: auto 1fr;

    #stages {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      width: 100%;
      margin: 2rem 0 auto 0;
    }

    .stage {
      min-height: auto;
    }

    .stage > img {
      width: 80%;
      grid-row: 1;
    }
  }

  .stage-panel {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .subpanel_image {
    min-height: auto;
  }
  .subpanel_image > img {
    max-width: 500px;
  }

  .subpanel_text {
    padding: 3rem;
  }

  #last-panel {
    padding: 3rem;
  }

  #last-panel_text {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    max-width: none;
    margin: 0;
  }
}
