.home {
  padding: 0 4vw;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;


  .title {
    font-family: var(--secondaryFont);
    margin: 2vh 0 2vh 0;
  }

  .create-button {
    text-align: left;
    margin-top: 5vw;
  }

  .content {

    grid-column: 2;
  }

  .process-list {
    margin: 2.5em 0 2.5em 0;
    width: auto;
    position: relative;
  }

  .no-events {
    margin-top: 10vh;
  }
}

@media only screen and (min-width: 768px) {
  .home {

    .about {
      grid-column: 2 / -1;
      grid-row-start: 1;
    }

    .process-list {
      margin: 2.5em 0 2.5em 0;
      width: auto;
    }
  }
}
