.transfer-modal {
  display: block;
  padding: 1rem;
  text-align: center;
  justify-content: center;
  width: 90%;
  position: fixed;
  z-index: 100;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  overflow-y: auto;
  background: var(--whiteColor);
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.25);

  .give-credits-page {
    text-align: center;
    position: relative;

    .title {
      font-size: 2em;
    }

    .transfer-field-container {
      border: 1px solid black;
      border-radius: 0;
      width: 96%;
      height: 10vh;
      font-size: 1em;
      text-align: right;
      margin-bottom: 2rem;
      margin-right: 0;

      .field-label {
        text-align: left;
        width: 95%;
        margin: .25rem;
      }

      .amount-input {
        text-align: right;
        border: none;
        width: 95%;
        font-size: 1rem;
        padding: 0;
        margin-right: .25rem;
      }
      .amount-input:focus {
        outline: none;
      }

      .email-input {
        text-align: right;
        border: none;
        width: 95%;
        font-size: .7rem;
        margin-right: .25rem;
      }
      .email-input:focus {
        outline: none;
      }

      .recipient {
        margin: auto 0;
      }
    }

    .match-estimate {
      font-size: .8rem;
    }

    .button-container {
      width: 100%;
      text-align: right;
      margin-top: 2rem;

      .submit-button {
        display: inline-block !important;
        margin-right: 1rem !important;
      }

      .cancel-button {
        display: inline-block;
        background: inherit;
        border: none;
        color: black;
      }
    }

    .transfer-success {
      display: inline-block;
    }
    .hide {
      display: none;
    }

    .back-button {
      display: block;
      text-align: left;
      margin-top: 4rem;
      text-decoration: underline;
    }
    .back-button:hover {
      text-decoration: none;
    }
  }
}

.closed {
  display: none;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: rgb(28, 28, 28);
  will-change: opacity;
  opacity: 0.55;
  transition: opacity 0.25s ease 0s;
}

@media only screen and (min-width: 768px) {

  .transfer-modal {
    max-width: 500px;

    .give-credits-page {

      .transfer-field-container {
        height: 8vh;
        margin: 0 auto 2rem auto;

        .amount-input {
          margin-right: 1%;
          font-size: 1.2rem;
        }

        .email-input {
          font-size: 1rem;
        }

        .recipient {
          margin: auto 1% auto 0;
        }
      }

      .match-estimate {
        font-size: 1rem;
      }
    }
  }
}

// hide arrows in number fields

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
