.create-account {
  padding: 4.5vmin;
  text-align: center;

  .login-logo {
    width: 91vw;
    display: block;
    text-align: center;
    margin: 0 auto;
  }

  .app-title {
    display: block;
    text-align: center;
    margin: 0 auto;
    font-family: var(--primaryFont);
    font-size: 5em;
  }

  .login-input {
    display: block;
    text-align: center;
    margin: 8vmin auto 0 auto;
    width: 80%;
    border: none;
    outline: none;
    border-bottom: 1px solid black;
    font-size: 1rem;
  }

  .oauth-message {
    font-size: 1rem;
    margin: 8vmin auto 0 auto;
    text-align: left;
  }

  .oauth-provider {
    display: block;
    width: 80%;
    margin: 2vmin auto;
    font-size: 1rem;
    outline: none;
  }

  .attestation {
    border: 1px solid black;
    border-radius: 8px;
    padding: 1rem;
    margin-top: 8vmin;
  }

  .attestation > h3 {
    margin-top: 0;
  }

  .attestation > p {
    text-align: left;
  }

  .create-account-button {
    display: block;
    text-align: center;
    margin: 5vw auto 10vmin auto;
    padding: 1vmin 4vmin;
    background: var(--blackColor);
    color: white;
    border: 1px solid black;
    border-radius: 15px;
    outline: none;
    cursor: pointer;
    font-size: 1rem;
  }
  .create-account-button:hover {
    background: var(--whiteColor);
    color: black;
  }

  .text {
    margin-bottom: 3vw;
  }

  #error-message {
    margin: 5vw auto 0 auto;
  }

  .login-link {
    text-decoration: underline;
  }
  .login-link:hover {
    text-decoration: none;
  }
}

.login-header {
  text-align: right;
  margin-right: 3vw;

  .nav-link {
    text-decoration: underline;
  }
  .nav-link:hover {
    text-decoration: none;
  }
}

.email-notice {
  max-width: 400px;
  margin: auto;
}

@media only screen and (min-width: 768px) {
  .create-account {
    padding: 2vh 30vw;

    .login-logo {
      width: 39.31vw;
    }

    .app-title {

    }

    .login-input {
      text-align: left;
    }

    .login-input {
      font-size: 1.5vw;
    }
  }
}
