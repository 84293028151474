#remaining-credits-blocks {
  padding: .5rem !important;
  border: none !important;
  max-height: 10vh;
  width: 100%;
  object-fit: contain;
}

@media only screen and (min-width: 768px) {
#remaining-credits-blocks {
    max-height: none;
    max-width: 80%;
    height: 40vh;
  }
}
