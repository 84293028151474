.transfer-card {
  display: grid;
  grid-template-columns: 50% 50%;

  .sender {
    grid-column: 1;
  }
  .amount {
    grid-column: 2;
  }
}
