.voting-page {
  grid-column: 1;

  .proposal-list {
    padding: 2vw 1vw 0 0;
  }

  .insufficient-credits {
    font-size: 1rem;
    padding: 1rem;
    border: 1px solid black;
    border-radius: 8px;
  }
  .success-modal {
    display: block;
    padding: 1rem;
    text-align: center;
    width: 90%;
    position: fixed;
    z-index: 100;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    overflow-y: auto;
    background: var(--whiteColor);
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.25);
  }

  .closed {
    display: none;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    background-color: rgb(28, 28, 28);
    will-change: opacity;
    opacity: 0.55;
    transition: opacity 0.25s ease 0s;
  }

  .button-container {
    text-align: center;
    padding: 1vw 2vw;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background: white;
    font-size: .8rem;

    .votes-cast {
      display: none;
    }
  }
}

.submit-button {
  display: block;
  margin: 1em auto 1em auto;
  background: black;
  color: white;
  border: 1px solid black;
  border-radius: 15px;
  padding: .5em 1em .5em 1em;
}

.results-page {
  text-align: left;
  margin-top: 5vw;
  font-size: .8rem;
  padding-bottom: 18vh;
  max-width: 700px;

  #download {
    margin-bottom: 2rem;
    margin-left: 0;
  }
}

@media only screen and (min-width: 768px) {
  .voting-page {
    padding-right: 15vw;

    .proposal-list {
    }
    .success-modal {
      max-width: 500px;
    }

    .button-container {
      position: fixed;
      left: auto;
      top: auto;
      padding: 0 2vw;
      background: none;
      grid-column: 3;
      right: 2vw;
      bottom: 5vh;

      .votes-cast {
        display: block;
      }
    }
  }
}
