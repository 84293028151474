.about {
  text-align: left;
  max-width: 800px;
  margin: 0 auto;

  .header {
    text-align: center;
    margin-bottom: 4rem;

    .title {
      font-size: 1.5em;
    }
  }

  .panel {
    text-align: left;
    min-height: 0;
    width: 100%;
    border-bottom: 1px solid black;
  }

  #about-info > section:nth-child(odd) > div.subpanel_text {
    grid-column: 1;
    grid-row: 1;
  }

  #about-info > section:nth-child(odd) > div.subpanel_image {
    grid-column: 2;
    grid-row: 1;
  }

  .privacy-title {
    margin-top: 4rem;
    font-size: 1.8rem;
  }
}

@media only screen and (min-width: 768px) {
  .about {
    text-align: left;

    .title {
      font-size: 2em;
    }
  }
}
