.App-header {
  margin-bottom: 8vh;

  .logo-container {
    width: 91vw;

    .App-logo {
      width: 91vw;
    }
  }

  .app-title {
    font-family: var(--primaryFont);
    font-size: 20vw;
    margin-right: auto;
  }

  .nav {
    display: flex;
    justify-content: space-between;
    margin: 3em 0 0 0;
    padding: 0;
    font-size: 4vw;
    text-transform: uppercase;

    .nav-link {
      margin-bottom: auto;
    }
    .credit-balance {
      // text-transform: none;
      margin: 0 0 auto 0;
      font-size: inherit;
    }
  }
}

@media only screen and (min-width: 768px) {
  .App-header {
    display: grid;
    grid-template-columns: 1fr 40%;
    text-align: left;
    margin-bottom: 10vh;

    .logo-container {
      width: 39.31vw;

      .App-logo {
        width: 39.31vw;
      }
    }

    .app-title {
      font-size: 8vw;
      line-height: 7vw;
    }

    .nav {
      margin: 0;
      font-size: 1.5vw;

      .nav-link {
      }
    }
  }
}
