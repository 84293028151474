.login-unverified {
  padding: 4.5vmin;
  text-align: left;

  .list {
    list-style: decimal;
  }
}

.login-unverified > h2 {
  text-align: center;
}

.login {
  padding: 4.5vmin;

  .login-logo {
    width: 91vw;
    display: block;
    text-align: center;
    margin: 0 auto;
  }

  .login-input {
    display: block;
    text-align: center;
    margin: 8vmin auto 0 auto;
    width: 80%;
    border: none;
    outline: none;
    border-bottom: 1px solid black;
    font-size: 1rem;

  }

  .login-button {
    display: block;
    text-align: center;
    margin: 10vmin auto 5vmin auto;
    padding: 1vmin 4vmin;
    background: var(--blackColor);
    color: white;
    border: 1px solid black;
    border-radius: 15px;
    outline: none;
    cursor: pointer;
    font-size: 1rem;
  }
  .login-button:hover {
    background: var(--whiteColor);
    color: black;
  }
  .forgot-password {
    text-decoration: underline;
    font-size: .8rem;
  }
  .forgot-password:hover {
    text-decoration: none;
  }

}

@media only screen and (min-width: 768px) {
  .login-unverified {
    padding: 2vw 30vw;
  }

  .login {
    padding: 10vw 30vw;

    .login-logo {
      width: 39.31vw;
    }

    .login-input {
      font-size: 1.5vw;
    }
  }
}
